import { BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("cc_pssm_api");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = null;
  }

  return config;
});

export const ListDesAdmims = async (page, size, param) => {
  return await axios.get(
    BASE_URL(`utilisateur/query?page=${page}&size=${size}&param=${param}`),
    {}
  );
};

export const recupererListeCompleteAdmins = async () => {
  return await axios.get(
    BASE_URL(`utilisateur`),
    {}
  );
};

export const enregistrerAdmin = async (data) => {
  return await axios.post(BASE_URL("utilisateur"), data);
};

export const generateNewAdminPassword = async (userID) => {
  return await axios.post(BASE_URL(`utilisateur/generate-password/${userID}`), {});
};

export const supprminerAdmin = async (idAdmin) => {
  return await axios.delete(BASE_URL(`utilisateur/${idAdmin}`), {});
};

export const desactivUserById = async (idAdmin) => {
  return await axios.put(BASE_URL(`utilisateur/desactive/${idAdmin}`), {});
};

export const udpdateAdnim = async (idAdmin, data) => {
  return await axios.put(BASE_URL(`utilisateur/${idAdmin}`), data);
};
