import React, { useEffect, useState } from "react";
import BaseLayout from "../../layout/BaseLayout";
import iconeAdd from "../../assets/IconeSvg/boutonAdd.svg";
import iconethreeDots from "../../assets/IconeSvg/treeDotsGray.svg";
import iconeDelete from "../../assets/IconeSvg/delete.svg";
import iconeDefaultUser from "../../assets/IconeSvg/user-rounded.svg";
import iconePencil from "../../assets/IconeSvg/pencil.svg";
import iconeKey from "../../assets/IconeSvg/key.svg";
import iconeCloseWindow from "../../assets/IconeSvg/close_window.svg";
import iconeAppareilPhoto from "../../assets/IconeSvg/appareil_photo.svg";
import { Drawer, Pagination, Snackbar } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { listeCompleteDesCommissions } from "../../services/parametrage/CommissionRequest";
import { listeCompleteDesNiveauParCommission } from "../../services/parametrage/NiveauxRequest";
import {
  ajouterUneAnimateur,
  genererMotDePasseAnimateur,
  listeDesAnimateurPaginee,
  modifierUneAnimateur,
  supprimerUneAnimateur,
} from "../../services/animateur/AnimateurRequest";
import { listeCompleteDesClasses } from "../../services/parametrage/ClasseRequest";
import {
  returnPermissionsParMenu,
  verificationPermissionsExistantes,
} from "../../utils/ManageUserProfil";
const listePermissions = returnPermissionsParMenu("/animateurs");

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

const Animateurs = () => {
  const navigate = useNavigate();
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 10,
    nombrePage: 1,
    pagePagination: 1,
  });
  const [addLoading, setAddLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [listeAnimateurs, setListeAnimateurs] = useState([]);
  const [listeCommission, setListeCommission] = useState([]);
  const [listeNiveaux, setListeNiveaux] = useState([]);
  const [listeClasses, setListeClasses] = useState([]);
  const [commissionSelected, setCommissionSelected] = useState("");
  const [niveauSelected, setNiveauSelected] = useState("");
  const [classeSelected, setClasseSelected] = useState("");
  const [animateurInfo, setAnimateurInfo] = useState(null);
  const [searchParam, setSearchParam] = useState("");
  const [photoAnimateur, setPhotoAnimateur] = useState("");
  const [preview, setPreview] = useState("");
  const [animateurData, setAnimateurData] = useState({
    nom: "",
    prenoms: "",
    contact: "",
    secondContact: "",
    email: "",
    commission: "",
    niveau: "",
    photo: "",
    classe: "",
  });

  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = snackbarPosition;

  const AnimateurElement = ({ animateur }) => {
    return (
      <div className="relative w-full h-full">
        <div className="absolute right-2 top-2 z-[1]">
          <div className="dropdown dropdown-bottom dropdown-end">
            <div
              tabIndex={0}
              className="w-7 h-7 rounded-lg bg-gray-200 flex items-center justify-center cursor-pointer"
            >
              <img src={iconethreeDots} alt="" />
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu p-1 shadow border bg-base-100 rounded-lg w-44 mt-2"
            >
              {verificationPermissionsExistantes(
                listePermissions,
                "AJOUTER"
              ) && (
                <button
                  onClick={() => {
                    openModalModif(animateur);
                  }}
                  className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
                >
                  <img src={iconePencil} alt="" className="w-5" />
                  <span className="font-semibold text-sm">Modifier</span>
                </button>
              )}

              {verificationPermissionsExistantes(
                listePermissions,
                "AJOUTER"
              ) && (
                <button
                  onClick={() => {
                    document
                      .getElementById("update-password-animateur")
                      .showModal();
                    setAnimateurInfo(animateur);
                  }}
                  className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
                >
                  <img src={iconeKey} alt="" className="w-5" />
                  <span className="font-semibold text-sm">Mot de passe</span>
                </button>
              )}

              {verificationPermissionsExistantes(
                listePermissions,
                "SUPPRIMER"
              ) && (
                <button
                  onClick={() => {
                    setAnimateurInfo(animateur);
                    document.getElementById("delete_animateur").showModal();
                  }}
                  className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
                >
                  <img src={iconeDelete} alt="" className="w-5" />
                  <span className="font-semibold text-sm">Supprimer</span>
                </button>
              )}
            </ul>
          </div>
        </div>
        <div
          className="cursor-pointer w-full h-full bg-white rounded-xl p-4 border drop-shadow-sm"
          onClick={() =>
            verificationPermissionsExistantes(listePermissions, "VOIR_DETAIL")
              ? navigate(`/animateurs/${animateur._id}`)
              : ""
          }
        >
          <div className="h-16 w-16 rounded-full mx-auto bg-gray-100 flex items-center justify-center">
            {animateur && animateur.photo ? (
              <img
                src={animateur.photo}
                className="w-full h-full rounded-full"
              />
            ) : (
              <img src={iconeDefaultUser} className="w-8" />
            )}
          </div>
          <div className="mt-4">
            <p className="text-lg text-primary text-left font-extrabold">
              {animateur.nom} {animateur.prenoms}
            </p>
          </div>
          <div className="mt-1">
            <p className="text-sm text-gray-600 text-left font-semibold">
              Commission:{" "}
              {animateur.classe ? animateur.classe.commission.designation : "-"}
            </p>
          </div>
          <div className="mt-1">
            <p className="text-sm text-gray-600 text-left font-semibold">
              Niveau:{" "}
              {animateur.classe ? animateur.classe.niveau.designation : "-"}
            </p>
          </div>
          <div className="mt-1">
            <p className="text-sm text-gray-600 text-left font-semibold">
              Classe: {animateur.classe ? animateur.classe.designation : "-"}
            </p>
          </div>
          <div className="mt-1">
            <p className="text-sm text-gray-500 text-left font-medium">
              Date création:{" "}
              {new Date(animateur.createdAt).toLocaleDateString()}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPhotoAnimateur(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const openModalModif = (animateur) => {
    setOpenModal(true);
    setAnimateurInfo(animateur);
    setAnimateurData({
      nom: animateur.nom,
      prenoms: animateur.prenoms,
      contact: animateur.contact,
      secondContact: animateur.secondContact,
      email: animateur.email,
      commission: animateur.commission ? animateur.commission : "",
      niveau: animateur.niveau ? animateur.niveau : "",
      classe: animateur.classe ? animateur.classe._id : "",
      photo: animateur.photo,
    });
    getListeDesNiveauxParCommission(
      animateur.commission,
      true,
      animateur.niveau
    );
  };

  const getListeDesAnimateurs = () => {
    setLoadingData(true);
    listeDesAnimateurPaginee(1, pageQuery.size, searchParam, "", "", "")
      .then((res) => {
        setLoadingData(false);
        setListeAnimateurs(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES COMMISSIONS ===
  const getListeDesCommission = () => {
    listeCompleteDesCommissions()
      .then((res) => {
        setListeCommission(res.data);
        setListeNiveaux([]);
        setListeClasses([]);
        setNiveauSelected("");
        setClasseSelected("");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES NIVEAUX PAR COMISSION ===
  const getListeDesNiveauxParCommission = (
    commission = "",
    update = false,
    niveau = ""
  ) => {
    setListeNiveaux([]);
    if (!update) {
      setAnimateurData((prev) => ({
        ...prev,
        niveau: "",
        classe: "",
      }));
    }
    listeCompleteDesNiveauParCommission(commission)
      .then((res) => {
        getListeDesClassesParNiveau(commission, niveau, true);
        setListeNiveaux(res.data);
        setListeClasses([]);
        setNiveauSelected("");
        setClasseSelected("");
      })
      .catch((error) => {
        console.log("error", error);
        setListeNiveaux([]);
      });
  };

  // === RECUPERE LISTE DES CLASSES PAR NIVEAU ===
  const getListeDesClassesParNiveau = (
    commission = "",
    niveau = "",
    update = false
  ) => {
    setListeClasses([]);
    if (!update) {
      setAnimateurData((prev) => ({
        ...prev,
        classe: "",
      }));
    }
    listeCompleteDesClasses(commission, niveau)
      .then((res) => {
        setListeClasses(res.data);
        setClasseSelected("");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const clearFormEnregistrement = () => {
    setCommissionSelected("");
    setNiveauSelected("");
    setAnimateurInfo(null);
    setPhotoAnimateur("");
    setAnimateurData({
      nom: "",
      prenoms: "",
      contact: "",
      secondContact: "",
      email: "",
      commission: "",
      niveau: "",
      photo: "",
    });
  };

  const enregistrementAnimateur = () => {
    setAddLoading(true);
    const formData = new FormData();
    formData.append("photo", photoAnimateur);
    formData.append("nom", animateurData.nom);
    formData.append("prenoms", animateurData.prenoms);
    formData.append("contact", animateurData.contact);
    formData.append("secondContact", animateurData.secondContact);
    formData.append("email", animateurData.email);
    formData.append("commission", animateurData.commission);
    formData.append("classe", animateurData.classe);
    formData.append("niveau", animateurData.niveau);
    formData.append("anneeScolaire", currentAnnee ? currentAnnee._id : null);

    ajouterUneAnimateur(formData)
      .then((res) => {
        clearFormEnregistrement();
        setAddLoading(false);
        setOpenModal(false);
        setSuccessMessage("Animateur enregistré avec succès.");
        setSnackbarOpenSuccess(true);
        getListeDesAnimateurs();
      })
      .catch((err) => {
        setAddLoading(false);
        if (err && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
      });
  };

  const modifierAnimateur = () => {
    setAddLoading(true);
    const formData = new FormData();
    if (photoAnimateur) {
      formData.append("photo", photoAnimateur);
    }
    formData.append("nom", animateurData.nom);
    formData.append("prenoms", animateurData.prenoms);
    formData.append("contact", animateurData.contact);
    formData.append("secondContact", animateurData.secondContact);
    formData.append("email", animateurData.email);
    formData.append("commission", animateurData.commission);
    formData.append("classe", animateurData.classe);
    formData.append("niveau", animateurData.niveau);
    formData.append("anneeScolaire", currentAnnee ? currentAnnee._id : null);

    modifierUneAnimateur(formData, animateurInfo._id)
      .then((res) => {
        clearFormEnregistrement();
        setAddLoading(false);
        setOpenModal(false);
        setSuccessMessage("Animateur modifié avec succès.");
        setSnackbarOpenSuccess(true);
        getListeDesAnimateurs();
        setListeNiveaux([]);
        setListeClasses([]);
      })
      .catch((err) => {
        setAddLoading(false);
        if (err && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
      });
  };

  const deleteAnimateur = () => {
    setDeleteLoading(true);

    supprimerUneAnimateur(animateurInfo._id)
      .then((res) => {
        setSuccessMessage("Animateur supprimé avec succès");
        getListeDesAnimateurs();
        setSnackbarOpenSuccess(true);
        setDeleteLoading(false);
        document.getElementById("delete_animateur").close();
      })
      .catch((err) => {
        setDeleteLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
      });
  };

  const generateNewPassword = () => {
    setAddLoading(true);

    genererMotDePasseAnimateur(animateurInfo._id)
      .then((res) => {
        setSuccessMessage("Nouveau mot de passe généré avec succès");
        getListeDesAnimateurs();
        setSnackbarOpenSuccess(true);
        setAddLoading(false);
        document.getElementById("update-password-animateur").close();
      })
      .catch((err) => {
        setAddLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
      });
  };

  // === FILTRER LES ELEMENTS ===
  const filterElement = () => {
    setSearchLoading(true);
    listeDesAnimateurPaginee(
      1,
      pageQuery.size,
      searchParam,
      commissionSelected,
      niveauSelected,
      classeSelected
    )
      .then((res) => {
        setSearchLoading(false);
        setListeAnimateurs(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setSearchLoading(false);
        console.log("error", error);
      });
  };

  // === PAGINATION DES ELEMENTS ===
  const handlePaginationChange = (event, value) => {
    setLoadingData(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value,
    }));
    listeDesAnimateurPaginee(
      value,
      pageQuery.size,
      searchParam,
      commissionSelected,
      niveauSelected,
      classeSelected
    )
      .then((res) => {
        setLoadingData(false);
        setListeAnimateurs(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  useEffect(() => {
    setLoadingData(true);
    getListeDesCommission();

    listeDesAnimateurPaginee(
      pageQuery.page,
      pageQuery.size,
      searchParam,
      "",
      "",
      ""
    )
      .then((res) => {
        setLoadingData(false);
        setListeAnimateurs(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  }, []);

  return (
    <BaseLayout>
      {verificationPermissionsExistantes(listePermissions, "VOIR_LISTE") ? (
        <div className="w-full h-full pb-16">
          <div className="w-full">
            <h1 className="text-2xl md:text-3xl font-extrabold">Animateurs</h1>
            <p className="mt-2 text-gray-500 font-medium text-sm sm:text-base md:text-lg">
              Ajouter, modifier, supprimer des animateurs
            </p>
          </div>

          <div className="mt-8 flex flex-col lg:flex-row justify-end items-end lg:items-start gap-2">
            {/* FILTRE DES ANIMATEURS */}
            <div className="w-full md:w-fit flex items-center gap-x-2 overflow-x-scroll sm:overflow-auto">
              <input
                type="text"
                placeholder="Rechercher un élement..."
                value={searchParam}
                onChange={(e) => setSearchParam(e.target.value)}
                className="input input-bordered w-64 sm:w-56 h-10 text-sm font-medium"
              />
              <select
                value={commissionSelected}
                onChange={(e) => {
                  setCommissionSelected(e.target.value);
                  getListeDesNiveauxParCommission(e.target.value);
                }}
                className="select select-bordered custom-select w-32 h-10 font-medium"
              >
                <option disabled selected value="">
                  Commission
                </option>
                <option value="" className="font-medium">
                  Tout
                </option>
                {listeCommission.map((item, index) => (
                  <option key={index} value={item._id} className="font-medium">
                    {item.designation}
                  </option>
                ))}
              </select>
              <select
                value={niveauSelected}
                onChange={(e) => {
                  setNiveauSelected(e.target.value);
                  getListeDesClassesParNiveau(
                    commissionSelected,
                    e.target.value
                  );
                }}
                className="select select-bordered custom-select w-36 h-10 font-medium"
              >
                <option disabled selected value="">
                  Niveau
                </option>
                <option value="" className="font-medium">
                  Tout
                </option>
                {listeNiveaux.map((item, index) => (
                  <option key={index} value={item._id} className="font-medium">
                    {item.designation}
                  </option>
                ))}
              </select>
              <select
                value={classeSelected}
                onChange={(e) => {
                  setClasseSelected(e.target.value);
                }}
                className="select select-bordered custom-select w-40 h-10 font-medium"
              >
                <option disabled selected value="">
                  Classe
                </option>
                <option value="" className="font-medium">
                  Tout
                </option>
                {listeClasses.map((item, index) => (
                  <option key={index} value={item._id} className="font-medium">
                    {item.designation}
                  </option>
                ))}
              </select>
              <button
                disabled={searchLoading}
                className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center"
                onClick={filterElement}
              >
                {!searchLoading ? (
                  "Rechercher"
                ) : (
                  <ThreeDots
                    height="35"
                    width="35"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={searchLoading}
                  />
                )}
              </button>
            </div>
            {verificationPermissionsExistantes(listePermissions, "AJOUTER") ? (
              <button
                onClick={() => {
                  clearFormEnregistrement();
                  setOpenModal(true);
                }}
                className="bg-black w-full sm:w-fit h-10 px-4 flex items-center justify-center gap-x-2 rounded-xl"
              >
                <img src={iconeAdd} alt="" className="w-5 sm:w-auto" />
                <span className="text-white text-sm font-semibold truncate">
                  Ajouter un animateur
                </span>
              </button>
            ) : null}
            <Link to="/animateurs/print-badge">
              <button className="bg-primary w-full sm:w-fit h-10 px-4 flex items-center justify-center gap-x-2 rounded-xl">
                <span className="text-white text-sm font-semibold truncate">
                  Générer badges
                </span>
              </button>
            </Link>
          </div>

          {!loadingData && listeAnimateurs.length !== 0 ? (
            <div className="mt-8 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-2 sm:gap-4 w-full h-full">
              {listeAnimateurs.map((animateur, index) => (
                <AnimateurElement animateur={animateur} key={index} />
              ))}
            </div>
          ) : null}

          {!loadingData && listeAnimateurs.length === 0 ? (
            <div className="mt-5 h-56 w-full flex items-center justify-center">
              <p className="text-red-600 font-semibold">
                Aucun élément trouvé.
              </p>
            </div>
          ) : null}

          {loadingData && listeAnimateurs.length === 0 ? (
            <div className="mt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-4 w-full">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                <div
                  key={item}
                  className="w-full bg-white rounded-xl p-4 border drop-shadow-sm"
                >
                  <div className="skeleton h-16 w-16 rounded-full mx-auto"></div>
                  <div className="skeleton h-5 w-11/12 mx-auto rounded mt-4"></div>
                  <div className="skeleton h-5 w-9/12 mx-auto rounded mt-2"></div>
                </div>
              ))}
            </div>
          ) : null}

          {loadingData && listeAnimateurs.length !== 0 ? (
            <div className="mt-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-4 w-full">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                <div
                  key={item}
                  className="w-full bg-white rounded-xl p-4 border drop-shadow-sm"
                >
                  <div className="skeleton h-16 w-16 rounded-full mx-auto"></div>
                  <div className="skeleton h-5 w-11/12 mx-auto rounded mt-4"></div>
                  <div className="skeleton h-5 w-9/12 mx-auto rounded mt-2"></div>
                </div>
              ))}
            </div>
          ) : null}

          <div className="flex justify-end mt-5">
            <Pagination
              count={pageQuery.nombrePage}
              page={pageQuery.pagePagination}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginationChange}
            />
          </div>
        </div>
      ) : (
        <div className="h-56 flex items-center justify-center">
          <p className="text-base text-primary font-extrabold">
            Vous n'avez pas accès à ce menu.
          </p>
        </div>
      )}

      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className="w-screen sm:w-[430px] pt-2 px-4 flex flex-col bg-white ">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <img src={iconeCloseWindow} alt="" />
          </button>

          <h2 className="mt-3 text-xl font-extrabold">
            {animateurInfo === null
              ? "Ajouter un animateur"
              : "Modifier cet animateur"}
          </h2>

          {/* UPLOAD PHOTO ANIMATEUR */}
          <div className="flex justify-center items-center  mt-6 h-24 w-full ">
            <div
              onClick={() => {
                document.getElementById("photo-profil").click();
              }}
              className="relative flex justify-center items-center w-20 mx-auto h-20 bg-gray-200 rounded-full cursor-pointer border-4 border-black"
            >
              <button
                className="absolute -top-3 -right-3 w-9 h-9 rounded-full flex items-center justify-center bg-white border drop-shadow z-50"
                onClick={() => {
                  setPreview("");
                  setPhotoAnimateur("");
                }}
              >
                <img src={iconeDelete} className="w-5" />
              </button>

              {photoAnimateur && animateurInfo !== null ? (
                <div className="relative w-20 h-20 rounded-full">
                  <img
                    src={photoAnimateur ? preview : animateurInfo.photo}
                    alt="Preview"
                    className="w-full h-full rounded-full object-cover"
                  />
                </div>
              ) : null}

              {photoAnimateur && animateurInfo === null ? (
                <div className="relative w-20 h-20 rounded-full">
                  <img
                    src={preview}
                    alt="Preview"
                    className="w-full h-full rounded-full object-cover"
                  />
                </div>
              ) : null}

              {!photoAnimateur && animateurInfo !== null ? (
                <div className="w-20 h-20 relative flex items-center justify-center rounded-full">
                  <img
                    src={animateurInfo.photo}
                    alt="photo animateur"
                    className="w-full h-full rounded-full object-cover"
                  />
                </div>
              ) : null}

              {!photoAnimateur && animateurInfo === null ? (
                <div className="w-20 h-20 relative flex items-center justify-center rounded-full">
                  <img
                    src={iconeAppareilPhoto}
                    alt="photo animateur"
                    className="w-8 h-8"
                  />
                </div>
              ) : null}
            </div>
            <input
              type="file"
              id="photo-profil"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>

          <div className="mt-6">
            <div className="w-full flex items-center gap-x-3">
              <div className="w-full">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Nom
                    </span>
                  </div>
                  <input
                    type="text"
                    disabled={addLoading}
                    value={animateurData.nom}
                    onChange={(e) => {
                      setAnimateurData((prev) => ({
                        ...prev,
                        nom: e.target.value,
                      }));
                    }}
                    placeholder="Entrer une designation"
                    className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                  />
                </label>
              </div>
              <div className="w-full">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Prénoms
                    </span>
                  </div>
                  <input
                    type="text"
                    disabled={addLoading}
                    value={animateurData.prenoms}
                    onChange={(e) => {
                      setAnimateurData((prev) => ({
                        ...prev,
                        prenoms: e.target.value,
                      }));
                    }}
                    placeholder="Entrer une description"
                    className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                  />
                </label>
              </div>
            </div>
            <div className="flex items-center gap-x-3">
              <div className="w-full">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Contact
                    </span>
                  </div>
                  <input
                    type="text"
                    disabled={addLoading}
                    value={animateurData.contact}
                    onChange={(e) => {
                      setAnimateurData((prev) => ({
                        ...prev,
                        contact: e.target.value,
                      }));
                    }}
                    placeholder="Entrer une designation"
                    className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                  />
                </label>
              </div>
              <div className="w-full">
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Second contact
                    </span>
                  </div>
                  <input
                    type="text"
                    disabled={addLoading}
                    value={animateurData.secondContact}
                    onChange={(e) => {
                      setAnimateurData((prev) => ({
                        ...prev,
                        secondContact: e.target.value,
                      }));
                    }}
                    placeholder="Entrer une description"
                    className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                  />
                </label>
              </div>
            </div>
            <div className="">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Email
                  </span>
                </div>
                <input
                  type="text"
                  disabled={addLoading}
                  value={animateurData.email}
                  onChange={(e) => {
                    setAnimateurData((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                  }}
                  placeholder="Entrer une adresse email"
                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                />
              </label>
            </div>
            <div className="flex items-end gap-3">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Commission
                  </span>
                </div>
                <select
                  disabled={addLoading}
                  value={animateurData.commission}
                  onChange={(e) => {
                    setAnimateurData((prev) => ({
                      ...prev,
                      commission: e.target.value,
                    }));
                    setCommissionSelected(e.target.value);
                    getListeDesNiveauxParCommission(e.target.value);
                  }}
                  className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                >
                  <option disabled selected value="">
                    Choisir un élément
                  </option>
                  {listeCommission.map((item, index) => (
                    <option
                      key={index}
                      value={item._id}
                      className="font-medium"
                    >
                      {item.designation}
                    </option>
                  ))}
                </select>
              </label>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Niveau
                  </span>
                </div>
                <select
                  disabled={addLoading}
                  value={animateurData.niveau}
                  onChange={(e) => {
                    getListeDesClassesParNiveau(
                      animateurData.commission,
                      e.target.value
                    );
                    setAnimateurData((prev) => ({
                      ...prev,
                      niveau: e.target.value,
                    }));
                  }}
                  className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                >
                  <option disabled selected value="">
                    Choisir un élément
                  </option>
                  {listeNiveaux.map((item, index) => (
                    <option
                      key={index}
                      value={item._id}
                      className="font-medium"
                    >
                      {item.designation}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Classe
                  </span>
                </div>
                <select
                  disabled={addLoading}
                  value={animateurData.classe}
                  onChange={(e) => {
                    setAnimateurData((prev) => ({
                      ...prev,
                      classe: e.target.value,
                    }));
                  }}
                  className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                >
                  <option disabled selected value="">
                    Choisir un élément
                  </option>
                  {listeClasses.map((item, index) => (
                    <option
                      key={index}
                      value={item._id}
                      className="font-medium"
                    >
                      {item.designation}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            <button
              disabled={addLoading}
              onClick={() =>
                animateurInfo === null
                  ? enregistrementAnimateur()
                  : modifierAnimateur()
              }
              className="mt-10 bg-primary flex items-center justify-center w-full h-11 font-medium text-sm text-white rounded-lg hover:drop-shadow-md"
            >
              {!addLoading ? (
                <span>
                  {animateurInfo === null
                    ? "Enregistrer cet animateur"
                    : "Modifier cet animateur"}
                </span>
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={addLoading}
                />
              )}
            </button>
          </div>
        </div>
      </Drawer>

      {/* MODAL UPDATE PASSWORD ANIMATEUR */}
      <dialog id="update-password-animateur" className="modal">
        <div className="modal-box p-4 rounded-lg">
          <form method="dialog">
            <button
              disabled={addLoading}
              className="w-8 h-8 absolute right-2 top-2"
            >
              <img
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-black">
            Générer un mot de passe pour cet animateur
          </h3>
          <div className="py-4">
            <p className="text-gray-500 font-semibold">
              Cette action entrainera la génération d'un nouveau mot de passe
              pour cet animateur{" "}
              <span className="text-primary">
                (
                {animateurInfo
                  ? `${animateurInfo.nom} ${animateurInfo.prenoms}`
                  : "•••"}
                )
              </span>{" "}
              et qui lui sera envoyé par SMS.
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {addLoading ? (
                <div
                  disabled={addLoading}
                  className="w-full h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={addLoading}
                  className="w-full h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={generateNewPassword}
                disabled={addLoading}
                className="mt-2 sm:mt-0 w-full px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!addLoading ? (
                  "Générer et envoyer"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={addLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      {/* MODAL DELETE ANIMATEUR */}
      <dialog id="delete_animateur" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-center text-primary">
            Supprimer cet animateur
          </h3>
          <div className="py-4">
            <p className="text-center text-base font-medium text-gray-600">
              Attention action est irrversible et entrainera la suppression de
              cet animateur
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {deleteLoading ? (
                <div
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={deleteLoading}
                  className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={deleteAnimateur}
                disabled={deleteLoading}
                className="mt-2 sm:mt-0 w-full sm:w-56 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!deleteLoading ? (
                  "Supprimer quand même"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={deleteLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
        >
          <div className="bg-green-500 text-white px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
        >
          <div className="bg-red-500 text-white px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </BaseLayout>
  );
};

export default Animateurs;
