import React, { useEffect, useState } from "react";
import iconeAdd from "../../../assets/IconeSvg/boutonAdd.svg";
import iconethreeDots from "../../../assets/IconeSvg/treeDotsGray.svg";
import iconeDelete from "../../../assets/IconeSvg/delete.svg";
import iconePencil from "../../../assets/IconeSvg/pencil.svg";
import iconeCloseWindow from "../../../assets/IconeSvg/close_window.svg";
import { Drawer, Pagination, Snackbar } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { listeCompleteDesCommissionsParAnnee } from "../../../services/parametrage/CommissionRequest";
import {
  ajouterUneTarification,
  listeDesTarificationPaginee,
  modifierUneTarification,
  supprimerUneTarification,
} from "../../../services/parametrage/TarificationRequest";
import { listeCompleteDesMotifPaiements } from "../../../services/parametrage/MotifPaiementRequest";

const connectedUser = localStorage.getItem("cc_pssm_admin")
  ? JSON.parse(localStorage.getItem("cc_pssm_admin"))
  : null;
const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

const Tarification = () => {
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 10,
    nombrePage: 1,
    pagePagination: 1,
  });
  const [addLoading, setAddLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [listeTarification, setListeTarification] = useState([]);
  const [listeMotifPaiement, setListeMotifPaiement] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [montant, SetMontant] = useState("");
  const [description, setDescription] = useState("");
  const [motifSelected, setMotifSelected] = useState("");
  const [tarificationInfo, setTarificationInfo] = useState(null);

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = snackbarPosition;

  const TableElement = ({ tarification }) => {
    return (
      <tr className=" bg-white">
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {tarification.montant ? `${tarification.montant} FCFA` : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {tarification.motifPaiement && tarification.motifPaiement.designation
            ? tarification.motifPaiement.designation
            : "-"}
        </td>
        <td className="px-4 truncate text-sm text-stone-500 font-medium">
          {tarification.motifPaiement && tarification.motifPaiement.description
            ? tarification.motifPaiement.description
            : "-"}
        </td>

        <td className="px-4">
          <div className="dropdown dropdown-left dropdown-end">
            <div
              tabIndex={0}
              className="w-7 h-7 rounded-lg bg-gray-200 flex items-center justify-center cursor-pointer"
            >
              <img src={iconethreeDots} alt="" />
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content z-50 menu p-1 shadow border bg-base-100 rounded-lg w-32 mr-2"
            >
              <button
                onClick={() => {
                  setTarificationInfo(tarification);
                  openModalModif(tarification);
                }}
                className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
              >
                <img src={iconePencil} alt="" className="w-5" />
                <span className="font-semibold text-sm">Modifier</span>
              </button>

              <button
                onClick={() => {
                  setTarificationInfo(tarification);
                  document.getElementById("delete_tarification").showModal();
                }}
                className="w-full h-9 px-2 hover:bg-zinc-100 flex gap-x-1 items-center justify-start rounded-md"
              >
                <img src={iconeDelete} alt="" className="w-5" />
                <span className="font-semibold text-sm">Supprimer</span>
              </button>
            </ul>
          </div>
        </td>
      </tr>
    );
  };

  const openModalModif = (tarification) => {
    setOpenModal(true);
    setTarificationInfo(tarification);
    SetMontant(tarification.montant);
    setDescription(tarification.description);
    setMotifSelected(tarification.motifPaiement ? tarification.motifPaiement._id : "");
  };

  // === VIDE FORMULAIRE ===
  const clearFormEnregistrement = () => {
    setTarificationInfo(null);
    setAddLoading(false);
    SetMontant("");
    setDescription("");
    setMotifSelected("");
  };

  // === RECUPERE LISTE DES COMMISSIONS ===
  const getListeDesMotifDePaiement = () => {
    listeCompleteDesMotifPaiements(currentAnnee._id)
      .then((res) => {
        setListeMotifPaiement(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // === RECUPERE LISTE DES NIVEAUX ===
  const getListeDesTarification = () => {
    setLoadingData(true);
    listeDesTarificationPaginee(pageQuery.page, pageQuery.size, "", "")
      .then((res) => {
        setLoadingData(false);
        setListeTarification(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  // === ENREGISTRE UNE NIVEAU ===
  const enregistrerTarification = () => {
    setAddLoading(true);
    const tarificationData = {
      montant: montant,
      description: description,
      motifPaiement: motifSelected,
      anneeScolaire: currentAnnee ? currentAnnee._id : "",
      origine: connectedUser ? connectedUser._id : "",
    };

    ajouterUneTarification(tarificationData)
      .then((res) => {
        clearFormEnregistrement();
        setAddLoading(false);
        setOpenModal(false);
        setSuccessMessage("Tarification enregistréee avec succès.");
        setSnackbarOpenSuccess(true);
        getListeDesTarification();
      })
      .catch((err) => {
        setAddLoading(false);
        if (err && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("ELEMENT", err);
      });
  };

  // === MODIFIER UNE NIVEAU ===
  const modifierTarification = () => {
    setAddLoading(true);
    const tarificationData = {
      montant: montant,
      description: description,
      motifPaiement: motifSelected,
      anneeScolaire: currentAnnee ? currentAnnee._id : "",
      origine: connectedUser ? connectedUser._id : "",
    };

    modifierUneTarification(tarificationData, tarificationInfo._id)
      .then((res) => {
        clearFormEnregistrement();
        setAddLoading(false);
        setOpenModal(false);
        setSuccessMessage("Tarification modifiée avec succès.");
        setSnackbarOpenSuccess(true);
        getListeDesTarification();
      })
      .catch((err) => {
        setAddLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("ELEMENT", err);
      });
  };

  // === SUPPRIMER UNE NIVEAU ===
  const supprimerTarification = () => {
    setDeleteLoading(true);

    supprimerUneTarification(tarificationInfo._id)
      .then((res) => {
        setSuccessMessage("Tarification supprimé avec succès");
        getListeDesTarification();
        setSnackbarOpenSuccess(true);
        setDeleteLoading(false);
        document.getElementById("delete_tarification").close();
      })
      .catch((err) => {
        setDeleteLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("api error", err);
      });
  };

  // === FILTRER LES ELEMENTS ===
  const filterElement = () => {
    setSearchLoading(true);
    listeDesTarificationPaginee(
      pageQuery.page,
      pageQuery.size,
      searchParam,
      motifSelected
    )
      .then((res) => {
        setSearchLoading(false);
        setListeTarification(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setSearchLoading(false);
        console.log("error", error);
      });
  };

  // === PAGINATION DES ELEMENTS ===
  const handlePaginationChange = (event, value) => {
    setLoadingData(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value,
    }));
    listeDesTarificationPaginee(value, pageQuery.size, searchParam, motifSelected)
      .then((res) => {
        setLoadingData(false);
        setListeTarification(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  };

  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  useEffect(() => {
    setLoadingData(true);

    getListeDesMotifDePaiement();

    listeDesTarificationPaginee(pageQuery.page, pageQuery.size, "", "")
      .then((res) => {
        setLoadingData(false);
        setListeTarification(res.data.results);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements,
        }));
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  }, []);

  return (
    <div className="w-full h-full">
      <div className="flex flex-col lg:flex-row justify-end items-end lg:items-center gap-2">
        <div className="w-full sm:w-fit flex items-center gap-x-2 overflow-x-scroll sm:overflow-auto">
          <input
            type="text"
            placeholder="Rechercher un élement..."
            value={searchParam}
            onChange={(e) => setSearchParam(e.target.value)}
            className="input input-bordered w-64 sm:w-56 h-10 text-sm font-medium"
          />
          <select
            value={motifSelected}
            onChange={(e) => setMotifSelected(e.target.value)}
            className="select select-bordered custom-select h-10 font-medium"
          >
            <option disabled selected value="">
              Choisir un élément
            </option>
            <option value="" className="font-medium">
              Tout
            </option>
            {listeMotifPaiement.map((item, index) => (
              <option value={item._id} className="font-medium">
                {item.designation}
              </option>
            ))}
          </select>
          <button
            disabled={searchLoading}
            className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-xl flex items-center justify-center"
            onClick={filterElement}
          >
            {!searchLoading ? (
              "Rechercher"
            ) : (
              <ThreeDots
                height="35"
                width="35"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={searchLoading}
              />
            )}
          </button>
        </div>
        <button
          onClick={() => {
            clearFormEnregistrement();
            setOpenModal(true);
          }}
          className="bg-black w-full sm:w-fit h-10 px-4 flex items-center justify-center gap-x-2 rounded-xl"
        >
          <img src={iconeAdd} alt="" className="w-5 sm:w-auto" />
          <span className="text-white text-sm font-semibold truncate">
            Ajouter un tarif
          </span>
        </button>
      </div>

      <div className="mt-2 w-full h-full">
        <div className="overflow-x-auto">
          <table className="custom-table table table-zebra w-full">
            <thead>
              <tr className="bg-black h-12">
                <th className="text-sm text-white">Montant</th>
                <th className="text-sm text-white">Motif paiement</th>
                <th className="text-sm text-white">Description</th>
                <th className="text-sm text-white">Action</th>
              </tr>
            </thead>
            <tbody>
              {!loadingData && listeTarification.length > 0 ? (
                listeTarification.map((item) => (
                  <TableElement key={item._id} tarification={item} />
                ))
              ) : !loadingData && listeTarification.length === 0 ? (
                <tr>
                  <td colSpan="4">
                    <div className="w-full h-32 bg-white flex gap-x-2 text-red-500 items-center justify-center">
                      <p className="font-semibold text-lg text-red-600">
                        Aucun élément trouvé
                      </p>
                    </div>
                  </td>
                </tr>
              ) : loadingData && listeTarification.length === 0 ? (
                <tr>
                  <td colSpan="4">
                    <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                      <TailSpin
                        height="30"
                        width="30"
                        color="#000"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={loadingData}
                      />
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="4">
                    <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 text-red-500 items-center justify-center">
                      <TailSpin
                        height="30"
                        width="30"
                        color="#000"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={loadingData}
                      />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-2">
          <Pagination
            count={pageQuery.nombrePage}
            page={pageQuery.pagePagination}
            variant="outlined"
            shape="rounded"
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => {
          clearFormEnregistrement()
          setOpenModal(false)
        }}
      >
        <div className="w-screen sm:w-[430px] p-4 flex flex-col bg-white h-full">
          <button onClick={() => {
            clearFormEnregistrement()
            setOpenModal(false)
          }}>
            <img src={iconeCloseWindow} alt="" />
          </button>

          <h2 className="mt-3 text-xl font-extrabold">
            {tarificationInfo === null ? " Ajouter un tarif" : "Modifier ce tarif"}
          </h2>

          <div className="mt-6">
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Motif paiement
                  </span>
                </div>
                <select
                  value={motifSelected}
                  onChange={(e) => setMotifSelected(e.target.value)}
                  className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                >
                  <option disabled selected value="">
                    Choisir un élément
                  </option>
                  {listeMotifPaiement.map((item, index) => (
                    <option key={index} value={item._id} className="font-medium">
                      {item.designation}
                    </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="mt-1">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Montant
                  </span>
                </div>
                <input
                  type="text"
                  value={montant}
                  onChange={(e) => SetMontant(e.target.value)}
                  disabled={addLoading}
                  placeholder="Entrer une montant"
                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                />
              </label>
            </div>
            <div className="mt-1">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Description
                  </span>
                </div>
                <input
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  disabled={addLoading}
                  placeholder="Entrer une description"
                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                />
              </label>
            </div>

            <button
              onClick={
                tarificationInfo === null
                  ? () => enregistrerTarification()
                  : () => modifierTarification()
              }
              disabled={addLoading}
              className="mt-8 bg-primary flex items-center justify-center w-full h-11 font-bold text-white rounded-lg hover:drop-shadow-md"
            >
              {!addLoading ? (
                <span>{tarificationInfo === null ? "Enregistrer" : "Modifier"}</span>
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={addLoading}
                />
              )}
            </button>
          </div>
        </div>
      </Drawer>

      {/* MODAL DELETE ENTREPRISE */}
      <dialog id="delete_tarification" className="modal">
        <div className="modal-box rounded-lg">
          <form method="dialog">
            <button className="w-7 h-7 absolute right-2 top-2">
              <img
                onClick={() => setOpenModal(false)}
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-center text-primary">
            Supprimer ce tarif
          </h3>
          <div className="py-4">
            <p className="text-center text-base font-medium text-gray-600">
              Attention action est irrversible et entrainera la suppression de
              ce tarif
            </p>
          </div>
          <div className="modal-action flex justify-center">
            <form method="dialog" className="w-full flex flex-col sm:flex-row justify-center gap-x-3">
              {/* if there is a button in form, it will close the modal */}
              {deleteLoading ? <div disabled={deleteLoading} className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold">
                Annuler
              </div> : <button disabled={deleteLoading} className="w-full sm:w-32 h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold">
                Annuler
              </button>}
              <div
                onClick={supprimerTarification}
                disabled={deleteLoading}
                className="mt-2 sm:mt-0 w-full sm:w-56 px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!deleteLoading ? (
                  "Supprimer quand même"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={deleteLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </dialog>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
          className="z-[999999]"
        >
          <div className="bg-green-500 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
          className="z-[999999]"
        >
          <div className="bg-red-500 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default Tarification;
