import { BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("cc_pssm_api");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = null;
  }

  return config;
}, function (error) {
  console.log('AN ERROR REQUEST', error)
});

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

export const listeDesPaiementPaginee = async (page = 1, size = 10, param = "", mode = "", motif = "", personne = "", dateDebut = "", dateFin = "", event = "", typeTransaction = "") => {  
  return await axios.get(
    BASE_URL(`paiement/query?page=${page}&size=${size}&param=${param}&mode=${mode}&motif=${motif}&personne=${personne}&dateDebut=${dateDebut}&dateFin=${dateFin}&event=${event}&typeTransaction=${typeTransaction}&anneeScolaire=${currentAnnee ? currentAnnee._id : ""}`),
    {}
  );
};

export const listeCompleteDesPaiements = async (mode = "", motif = "", personne = "", dateDebut = "", dateFin = "", event = "") => {
  return await axios.get(
    BASE_URL(`paiement/complete?mode=${mode}&motif=${motif}&personne=${personne}&dateDebut=${dateDebut}&dateFin=${dateFin}&event=${event}&anneeScolaire=${currentAnnee ? currentAnnee._id : ""}`),
    {}
  );
};

export const recupereRevenuEtDepenseGenere = async (dateDebut = "", dateFin = "") => {
  return await axios.get(
    BASE_URL(`paiement/statistique-revenu?dateDebut=${dateDebut}&dateFin=${dateFin}&anneeScolaire=${currentAnnee ? currentAnnee._id : ""}`),
    {}
  );
};

export const recuperePaiementParSonId = async (paiementId) => {
  return await axios.get(
    BASE_URL(`paiement/${paiementId}`),
    {}
  );
};

export const ajouterUnePaiement = async (values) => {
  return await axios.post(
    BASE_URL(`paiement`),
    values
  );
};

export const ajouterUneNouvelleDepenseAuPaiement = async (values) => {
  return await axios.post(
    BASE_URL(`paiement/enregistrer-depense`),
    values
  );
};

export const ajouterUnNouveauRevenuPaiement = async (values) => {
  return await axios.post(
    BASE_URL(`paiement/enregistrer-revenu
`),
    values
  );
}

export const modifierUnePaiement = async (values, paiementId) => {
  return await axios.put(
    BASE_URL(`paiement/${paiementId}`),
    values
  );
};

export const supprimerUnePaiement = async (paiementId) => {
  return await axios.delete(
    BASE_URL(`paiement/${paiementId}`),
    {}
  );
};
