import React from "react";
import { createHashRouter, Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "../utils/AuthGuard";
import Login from "../views/login/Login";
import NotFound from "../views/404/NotFound";
import ForgetPassword from "../views/forget-password/ForgetPassword";
import Classes from "../views/classes/Classes";
import Animateurs from "../views/animateurs/Animateurs";
import Catechumenes from "../views/catechumenes/Catechumenes";
import CatechumeneDetails from "../views/catechumenes/catechumeneDetails/CatechumeneDetails";
import Parametrages from "../views/parametres/Parametrage";
import Admissions from "../views/admissions/Admission";
import Dashboard from "../views/dashboard/Dashboard";
import Administrateurs from "../views/administrateurs/Administrateurs";
import Finances from "../views/finances/Finances";
import InscriptionOnline from "../views/inscriptionOnLine/InscriptionOnline";
import FelicitationInscription from "../views/inscriptionOnLine/FelicitationInscription";
import EvenementDetails from "../views/evenements/detail/EvenementDetails";
import AnimateurDetails from "../views/animateurs/detail/AnimateurDetails";
import ClasseDetails from "../views/classes/detail/ClasseDetails";
import NouveauCatechumene from "../views/inscriptionOnLine/NouveauCatechumene";
import AncienCatechumene from "../views/inscriptionOnLine/AncienCatechumene";
import PrintBadge from "../views/classes/print-badge/PrintBadge";
import NotificationSms from "../views/notification-sms/NotificationSms";
import GestionsRoles from "../views/gestion-role/GestionRole";
import CeremonieEvenement from "../views/ceremonie-evenement/CeremonieEvenement";
import InscriptionCeremonie from "../views/ceremonie-evenement/inscription-ceremonie";
import PrintBadgeAnimateur from "../views/animateurs/print-badge/PrintBadgeAnimateur";

const ProtectedRoute = () => {
  return isAuthenticated() ? <Outlet /> : <Navigate replace to="/login" />;
};

const Router = createHashRouter([
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/inscription-catechumene",
    element: <InscriptionOnline />,
  },
  {
    path: "/inscription-catechumene/nouveau",
    element: <NouveauCatechumene />,
  },
  {
    path: "/inscription-catechumene/ancien",
    element: <AncienCatechumene />,
  },
  {
    path :"/felicitation_inscription",
    element: <FelicitationInscription/>
  },
  {
    path: "/inscription-ceremonie/:ceremonieID",
    element: <InscriptionCeremonie />,
  },
  {
    path: "/inscription-ceremonie-catechese",
    element: <InscriptionCeremonie />,
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "/",
    element: <Navigate replace to="/dashboard" />,
  },
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/classes",
        element: <Classes />,
      },
      {
        path: "/classes/:classeId",
        element: <ClasseDetails />,
      },
      {
        path: "classes/:classeId/print-badge",
        element: <PrintBadge />,
      },
      {
        path: "/catechumenes",
        element: <Catechumenes />,
      },
      {
        path: "/catechumenes/:id",
        element: <CatechumeneDetails />,
      },
      {
        path: "/animateurs",
        element: <Animateurs />,
      },
      {
        path: "/animateurs/print-badge",
        element: <PrintBadgeAnimateur />,
      },
      {
        path: "/animateurs/:animateurId",
        element: <AnimateurDetails />,
      },
      {
        path: "/admissions",
        element: <Admissions />,
      },
      {
        path: "/evenements",
        element: <CeremonieEvenement />,
      },
      {
        path: "/evenements/:id",
        element: <EvenementDetails />,
      },
      {
        path: "/finances",
        element: <Finances />,
      },
      {
        path: "/parametrages",
        element: <Parametrages />,
      },
      {
        path: "/administrateurs",
        element: <Administrateurs />,
      },
      {
        path: "/config-role",
        element: <GestionsRoles />,
      },
      {
        path: "/notification-sms",
        element: <NotificationSms />,
      },
    ],
  },
]);

export default Router;
