import React, { useEffect, useState } from "react";
import iconeCloseWindow from "../assets/IconeSvg/close_window.svg";
import iconeCalendrier from "../assets/IconeSvg/calendrieTransparent.svg";
import iconeBurger from "../assets/IconeSvg/burger-simple.svg";
import iconeClasse from "./../assets/IconeSvg/Classe.svg";
import iconeDashboard from "./../assets/IconeSvg/dashboard.svg";
import iconeCatechumene from "./../assets/IconeSvg/catechumene.svg";
import iconeAnimateur from "./../assets/IconeSvg/animateur.svg";
import iconeAdmission from "./../assets/IconeSvg/admission.svg";
import iconeEvenement from "./../assets/IconeSvg/calendrierRed.svg";
import iconeFinance from "./../assets/IconeSvg/finances.svg";
import iconeParametrage from "./../assets/IconeSvg/parametrage.svg";
import iconeAdministrateur from "./../assets/IconeSvg/administrateur.svg";
import messageIcon from "./../assets/IconeSvg/message-icon.svg";
import NewSidebar from "../components/NewSidebar";
import LOGO from "./../assets/images/logo-preview.png";
import roleIcon from "./../assets/IconeSvg/shield-keyhole.svg";
import { listeCompleteDesAnneesCatechumique } from "../services/parametrage/AnneeScolaireRequest";
import { NavLink, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { ThreeDots } from "react-loader-spinner";
import { Snackbar } from "@mui/material";
import {
  changePasswordAdmin,
  recupererProfilUtilisateurConnecte,
} from "../services/authentification/AuthRequest";

const connectedUser = localStorage.getItem("cc_pssm_api")
  ? jwtDecode(localStorage.getItem("cc_pssm_api"))
  : null;

const anneeScolaireActive = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

const BaseLayout = (props) => {
  const navigate = useNavigate();
  const [listeAnneeScolaire, setListeAnneeScolaire] = useState([]);
  const [profilUtilisateur, setProfilUtilisateur] = useState(null);
  const [listeMenusVisible, setListeMenusVisible] = useState(null);
  const [currentAnneeScolaire, setCurrentAnneeScolaire] = useState(null);
  const [connectedUser, setConnectedUser] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [updatePassword, setUpdatePassword] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = snackbarPosition;

  const selectCurrentAnnee = (annee) => {
    setCurrentAnneeScolaire(annee);
    localStorage.setItem("currentAnnee", JSON.stringify(annee));
    window.location.reload();
  };

  const deconnectAdmin = () => {
    localStorage.removeItem("cc_pssm_api");
    navigate("/login");
  };

  const displayMenuBasedOnRole = (role) => {
    let display = false;
    if (role == "ADMIN" || role == "SUPER ADMIN") {
      display = true;
    }

    return display;
  };

  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  const modifierMonMotDePasse = () => {
    setUpdateLoading(true);
    setSuccessMessage("");
    setErrorMessage("");
    const values = {
      ...updatePassword,
      id: connectedUser ? connectedUser.id : "",
    };
    changePasswordAdmin(values)
      .then((res) => {
        setSnackbarOpenError(true);
        setSuccessMessage("Mot de passe modifié avec succès");
        setUpdateLoading(false);
        document.getElementById("update-password").close();
        setUpdatePassword({
          password: "",
          newPassword: "",
          confirmPassword: "",
        });
      })
      .catch((err) => {
        setUpdateLoading(false);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Une erreur s'est produite, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("api error", err);
      });
  };

  const getLeProfilUtilisateur = (userID) => {
    recupererProfilUtilisateurConnecte(userID)
      .then((res) => {
        const menusPermissions = res.data.menusPermissions;
        const dashboardIndex = menusPermissions.findIndex(menu => menu.menu.designation === "Dashboard");
        if (dashboardIndex > -1) {
          const [dashboardMenu] = menusPermissions.splice(dashboardIndex, 1);
          menusPermissions.unshift(dashboardMenu);
        }

        setProfilUtilisateur(res.data);
        setListeMenusVisible(menusPermissions);
        localStorage.setItem("user_profil", JSON.stringify(res.data));
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const returnCorrectIconPerMenu = (route) => {
    if (route === "/dashboard") {
      return iconeDashboard;
    }
    if (route === "/classes") {
      return iconeClasse;
    }
    if (route === "/catechumenes") {
      return iconeCatechumene;
    }
    if (route === "/animateurs") {
      return iconeAnimateur;
    }
    if (route === "/admissions") {
      return iconeAdmission;
    }
    if (route === "/evenements") {
      return iconeEvenement;
    }
    if (route === "/finances") {
      return iconeFinance;
    }
    if (route === "/parametrages") {
      return iconeParametrage;
    }
    if (route === "/administrateurs") {
      return iconeAdministrateur;
    }
    if (route === "/notification-sms") {
      return messageIcon;
    }
    if (route === "/config-role") {
      return roleIcon;
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("cc_pssm_api");
    const decoded = token ? jwtDecode(token) : null;
    setConnectedUser(decoded ? decoded.connected : null);

    getLeProfilUtilisateur(decoded.id);

    setInterval(() => {
      getLeProfilUtilisateur(decoded.id);
    }, 300000);

    listeCompleteDesAnneesCatechumique()
      .then((res) => {
        setListeAnneeScolaire(res.data);
        res.data.map((item) => {
          if (item.anneeActive && !anneeScolaireActive) {
            setCurrentAnneeScolaire(item);
            localStorage.setItem("currentAnnee", JSON.stringify(item));
          } else if (item.anneeActive && anneeScolaireActive) {
            setCurrentAnneeScolaire(anneeScolaireActive);
          }
        });
      })
      .catch((error) => {
        console.log("ERROR CONFIG", error);
      });
  }, []);

  return (
    <div className="w-full min-h-screen flex lg:justify-end">
      <NewSidebar listeMenus={listeMenusVisible} />
      <div className="layout-child">
        <div className="sticky top-0 left-0 flex justify-between lg:justify-end items-center border-b bg-white shadow w-full h-14 px-4 z-50">
          <div className="block lg:hidden">
            <div className="dropdown dropdown-start">
              <div tabIndex={0} role="button" className="m-1">
                <button className="bg-gray-200 w-11 h-11 rounded-full flex items-center justify-center">
                  <img src={iconeBurger} className="w-7" alt="" />
                </button>
              </div>
              <ul
                tabIndex={0}
                className="dropdown-content menu bg-base-100 rounded-xl border z-[999999] w-72 p-2 shadow"
              >
                <div className="">
                  {listeMenusVisible && listeMenusVisible.length > 0 && (
                    <>
                      {/* <NavLink to="/dashboard">
                        <button className="w-full h-11 text-sm text-gray-700 hover:text-black font-medium hover:font-extrabold my-1 px-4 flex items-center justify-start gap-x-2 rounded-xl">
                          <img src={iconeDashboard} className="w-5" />
                          <span>Dashboard</span>
                        </button>
                      </NavLink> */}
                      {listeMenusVisible.map((item, index) => (
                        <NavLink to={item.menu.route} key={index}>
                          <button className="w-full h-11 text-base text-gray-700 hover:text-black font-medium hover:font-extrabold my-1 px-3 flex items-center justify-start gap-x-2 rounded-xl">
                            <img
                              src={returnCorrectIconPerMenu(item.menu.route)}
                              className="w-6"
                            />
                            <span>{item.menu.designation}</span>
                          </button>
                        </NavLink>
                      ))}
                    </>
                  )}
                </div>
              </ul>
            </div>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="dropdown dropdown-end">
              <div tabIndex={0} role="button" className="m-1">
                <div className="flex flex-row justify-center items-center bg-emerald-600 px-3 rounded-full h-9 gap-x-2">
                  <img src={iconeCalendrier} className="w-5" alt="" />
                  {currentAnneeScolaire ? (
                    <span className="text-xs text-white font-semibold">
                      {currentAnneeScolaire.designation} [
                      {currentAnneeScolaire.anneeDebut} -{" "}
                      {currentAnneeScolaire.anneeFin}]
                    </span>
                  ) : null}
                </div>
              </div>
              <ul
                tabIndex={0}
                className="dropdown-content menu bg-base-100 rounded-lg border z-[1] w-max p-1 shadow"
              >
                {listeAnneeScolaire.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => selectCurrentAnnee(item)}
                    className="flex items-center justify-center"
                  >
                    <button className="text-xs text-black text-center font-semibold">
                      {item.designation} [{item.anneeDebut} - {item.anneeFin}]
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="block lg:hidden">
              <div className="dropdown dropdown-end">
                <div tabIndex={0} role="button" className="m-1">
                  <div className="w-11 h-11 rounded-full bg-gray-100">
                    <img
                      src={
                        connectedUser && connectedUser.photo
                          ? connectedUser.photo
                          : LOGO
                      }
                      className="w-full h-full rounded-full border shadow"
                    />
                  </div>
                </div>
                <ul
                  tabIndex={0}
                  className="dropdown-content menu bg-base-100 rounded-xl border z-[1] w-56 p-2 shadow"
                >
                  <button
                    className="w-full mx-auto h-11 bg-gray-100 text-sm text-black font-semibold flex items-center gap-x-2 px-4 rounded-xl"
                    onClick={() => {
                      document.getElementById("update-password").showModal();
                    }}
                  >
                    <span>Modifier mot de passe</span>
                  </button>
                  <button
                    className="mt-1 bg-primary h-10 w-full px-4 text-base text-left text-white font-semibold rounded-lg"
                    onClick={() => deconnectAdmin()}
                  >
                    Déconnexion
                  </button>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">{props.children}</div>
      </div>

      {/* MODAL MIS A JOUR DU MOT PASSE */}
      <dialog id="update-password" className="modal">
        <div className="modal-box rounded-lg max-w-md p-4">
          <form method="dialog">
            <button
              disabled={updateLoading}
              className="w-8 h-8 absolute right-2 top-2"
            >
              <img
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-2xl text-left text-black">
            Modifier mon mot de passe
          </h3>
          <div className="py-4">
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Ancien mot de passe
                  </span>
                </div>
                <input
                  type="text"
                  value={updatePassword.password}
                  onChange={(e) =>
                    setUpdatePassword((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                  disabled={updateLoading}
                  placeholder="Entrer votre ancien mot de passe"
                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                />
              </label>
            </div>
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Nouveau mot de passe
                  </span>
                </div>
                <input
                  type="text"
                  value={updatePassword.newPassword}
                  onChange={(e) =>
                    setUpdatePassword((prev) => ({
                      ...prev,
                      newPassword: e.target.value,
                    }))
                  }
                  disabled={updateLoading}
                  placeholder="Entrer votre ancien mot de passe"
                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                />
              </label>
            </div>
            <div>
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-bold text-gray-500 text-sm">
                    Confirmer nouveau mot de passe
                  </span>
                </div>
                <input
                  type="text"
                  value={updatePassword.confirmPassword}
                  onChange={(e) =>
                    setUpdatePassword((prev) => ({
                      ...prev,
                      confirmPassword: e.target.value,
                    }))
                  }
                  disabled={updateLoading}
                  placeholder="Entrer votre ancien mot de passe"
                  className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                />
              </label>
            </div>
          </div>
          <div className="modal-action flex justify-center">
            <form
              method="dialog"
              className="w-full flex flex-col sm:flex-row justify-center gap-x-3"
            >
              {/* if there is a button in form, it will close the modal */}
              {updateLoading ? (
                <div
                  disabled={updateLoading}
                  className="w-full h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </div>
              ) : (
                <button
                  disabled={updateLoading}
                  className="w-full h-10 bg-gray-300 text-gray-700 text-sm rounded-lg flex items-center justify-center font-semibold"
                >
                  Annuler
                </button>
              )}
              <div
                onClick={modifierMonMotDePasse}
                disabled={updateLoading}
                className="mt-2 sm:mt-0 w-full px-2 h-10 bg-primary text-white text-sm rounded-lg flex items-center justify-center font-semibold cursor-pointer"
              >
                {!updateLoading ? (
                  "Modifier"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={updateLoading}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={2000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
          className="z-[999999]"
        >
          <div
            className={`${errorMessage ? "bg-red-600" : successMessage ? "bg-green-600" : ""
              } text-white font-bold z-[999999] px-10 py-3 rounded-lg`}
          >
            {errorMessage && errorMessage}
            {successMessage && successMessage}
          </div>
        </Snackbar>
      </dialog>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackbarErrorOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbarError}
        key={vertical + horizontal}
        className="z-[999999]"
      >
        <div
          className={`${errorMessage ? "bg-red-600" : successMessage ? "bg-green-600" : ""
            } text-white font-bold z-[999999] px-10 py-3 rounded-lg`}
        >
          {errorMessage && errorMessage}
          {successMessage && successMessage}
        </div>
      </Snackbar>
    </div>
  );
};

export default BaseLayout;
