import { BASE_URL, FREE_BASE_URL } from "../BaseUrl.js";
import axios from "axios";

axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("cc_pssm_api");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers.Authorization = null;
    }

    return config;
  },
  function (error) {
    console.log("AN ERROR REQUEST", error);
  }
);

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

// /api/v1/metier/statistiques/query?anneeScolaire=

// Retourne le nombre d’animateur, nombre de classe, nombre de catéchumène et nombre de sms envoyé

export const getStatistiqueEnNombre = async (annee) => {
  return await axios.get(
    BASE_URL(`metier/statistiques?anneeScolaire=${annee}`),
    {}
  );
};

// /api/v1/metier/statistiques-paiement/query?anneeScolaire=&dateDebut=&dateFin=&typeTransaction=

// Retourne les paiements enregistré dans l’application selon le motif (inscription, bapteme, sortie…)

// typeTransaction c’est DEPENSE ou REVENU

export const getStatistiqueTransaction = async (
  dateDebut,
  dateFin,
  typeTransaction
) => {
  return await axios.get(
    BASE_URL(
      `metier/statistiques-paiement?anneeScolaire=${currentAnnee ? currentAnnee._id : ""}&dateDebut=${dateDebut}&dateFin=${dateFin}&typeTransaction=${typeTransaction}`
    ),
    {}
  );
};

// /api/v1/metier/statistiques-admission/query?anneeScolaire=&decision=&param=

// Retourne le nombre d’admission par commission en fonction de la décision du conseil (ADMIS, RECALE, RETROGRADE)

export const getStatistiqueAdmission = async (decision, param) => {
  return await axios.get(
    BASE_URL(
      `metier/statistiques-admission?anneeScolaire=${currentAnnee ? currentAnnee._id : ""}&decision=${decision}&param=${param}`
    ),
    {}
  );
};

// Le param c’est pour dire si tu tu veux qu’il te retourne le nombre d’admission par commission, niveau, ou classe

// Voici un exemple de requête
// /api/v1/metier/statistiques-admission/query?anneeScolaire=66b404e316d6fba231adc415&decision=ADMIS&param=commission
