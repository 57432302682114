import React, { useEffect, useState } from "react";
import BaseLayout from "../../layout/BaseLayout";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import IconeBilletArgent from "../../assets/IconeSvg/money-bag.svg";
import IconeGirlie from "../../assets/IconeSvg/hand-money.svg";
import iconeCloseWindow from "../../assets/IconeSvg/close_window.svg";
import { Autocomplete, Pagination, TextField } from "@mui/material";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import {
  ajouterUneNouvelleDepenseAuPaiement,
  ajouterUnNouveauRevenuPaiement,
  listeDesPaiementPaginee,
  recupereRevenuEtDepenseGenere,
  supprimerUnePaiement
} from "../../services/paiement/PaiementRequest";
import { listeCompleteDesMotifPaiements } from "../../services/parametrage/MotifPaiementRequest";
import { listeCompleteDesModePaiements } from "../../services/parametrage/ModePaiementRequest";
import { listeCompleteDesCatechumenesEnregistre } from "../../services/catechumene/CatechumeneRequest";
import { ListeCompleteEvent } from "../../services/evenements/EvenementRequests";
import { listeCompleteDesTarifications } from "../../services/parametrage/TarificationRequest";
import { recupererListeCompleteAdmins } from "../../services/administrateurs/AdminRequest";
import ImageComponent from "../../components/ImageComponent";
import { formatAmountPrice } from "../../utils/MoneyFormatter";
import {
  returnPermissionsParMenu,
  verificationPermissionsExistantes
} from "../../utils/ManageUserProfil";
const listePermissions = returnPermissionsParMenu("/finances");

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

const Finances = () => {
  registerLocale("fr", fr);
  const [pageQuery, setPageQuery] = useState({
    page: 0,
    size: 20,
    nombrePage: 1,
    pagePagination: 1
  });
  const [listeTransaction, setListeTransaction] = useState([]);
  const [listeMotifPaiement, setListeMotifPaiement] = useState([]);
  const [listeTarifPaiement, setListeTarifPaiement] = useState([]);
  const [listeModePaiement, setListeModePaiement] = useState([]);
  const [listeCatechumene, setListeCatechumene] = useState([]);
  const [listeEvenement, setListeEvenement] = useState([]);
  const [listeAdministrateurs, setListeAdministrateurs] = useState([]);
  const [statistiqueFinancier, setStatistiqueFinancier] = useState({
    revenue: 0,
    depense: 0
  });
  const [addLoading, setAddLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  const [param, setParam] = useState("");
  const [typeDepense, setTypeDepense] = useState("");
  const [motifSelected, setMotifSelected] = useState("");
  const [modeSelected, setModeSelected] = useState("");
  const [catechumeneSelectedEl, setCatechumeneSelectedEl] = useState(null);
  const [catechumeneSelected, setCatechumeneSelected] = useState("");
  const [eventSelected, setEventSelected] = useState("");
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const [paiementSelected, setPaiementSelected] = useState(null);
  const [previewRecuPaiement, setPreviewRecuPaiement] = useState(null);
  const [typeTransaction, setTypeTransaction] = useState(null);
  const [paiementField, setPaiementField] = useState({
    datePaiement: new Date(),
    photoRecuPaiement: "",
    montant: "",
    commentaire: "",
    isDepense: true,
    personne: "",
    modePaiement: "",
    motifPaiement: "",
    anneeScolaire: currentAnnee ? currentAnnee._id : null
  });
  const [revenuField, setRevenuField] = useState({
    datePaiement: new Date(),
    photoRecuPaiement: "",
    montant: "",
    commentaire: "",
    isDepense: false,
    personne: "",
    donateur: "",
    modePaiement: "",
    motifPaiement: "",
    anneeScolaire: currentAnnee ? currentAnnee._id : null
  });

  const [transactionField, setTransactionField] = useState({
    datePaiement: new Date(),
    photoRecuPaiement: "",
    montant: "",
    commentaire: "",
    isDepense: true, // Utilisé pour conditionner les champs spécifiques
    personne: "",
    modePaiement: "",
    motifPaiement: "",
    donnateur: "", // Champ spécifique aux revenus
    anneeScolaire: currentAnnee ? currentAnnee._id : null
  });

  const TableElement = ({ transaction }) => {
    return (
      <tr className="">
        <td className="px-4 text-sm text-stone-500 font-medium">
          <div className="w-24">
            <p>{formatDate(new Date(transaction.datePaiement))}</p>
          </div>
        </td>

        <td className="px-4 text-sm text-stone-500 font-medium">
          {transaction.evenement ? transaction.evenement.designation : "-"}
        </td>

        <td className="px-4 text-xs text-stone-500 font-medium">
          {transaction.modePaiement
            ? transaction.modePaiement.designation
            : "-"}
        </td>

        <td className="px-4 text-xs text-stone-500 font-medium">
          {transaction.motifPaiement
            ? transaction.motifPaiement.designation
            : "-"}
        </td>

        <td className="px-4 text-sm text-stone-500 font-medium truncate">
          {transaction.montant} <span className="text-[11px]">FCFA</span>
        </td>

        <td className="px-4 text-sm text-stone-500 font-medium">
          <div className="w-40">
            {transaction.personne && <p>
              {transaction.personne
                ? `${transaction.personne.nom} ${transaction.personne.prenoms}`
                : "-"}
            </p>}
            {transaction.donnateur && <p>
              {transaction.donnateur
                ? transaction.donnateur
                : "-"}
            </p>}
          </div>
        </td>

        <td className="px-4 text-sm text-stone-500 font-medium">
          {transaction.motifPaiement
            ? retournerMontantRestant(
                transaction.motifPaiement._id,
                transaction.montant
              )
            : "-"}
        </td>

        <td className="px-4 text-sm text-stone-500 font-medium">
          <div className="w-40">
            <p className="truncate">
              {transaction.commentaire ? transaction.commentaire : "-"}
            </p>
          </div>
        </td>
        <td className="px-4 text-sm text-stone-500 font-medium">
          {verificationPermissionsExistantes(
            listePermissions,
            "VOIR_DETAIL"
          ) ? (
            <button
              className="px-2 py-1 text-xs font-semibold bg-gray-200 text-gray-600 rounded"
              onClick={() => {
                document.getElementById("detail-paiement").showModal();
                setPaiementSelected(transaction);
              }}
            >
              Détails
            </button>
          ) : (
            "-"
          )}
          {/* <button
            className="px-2 py-1 text-xs font-semibold bg-primary text-white rounded"
            onClick={() => {
              supprimerTransaction(transaction._id);
            }}
          >
            DEL
          </button> */}
        </td>
      </tr>
    );
  };

  const supprimerTransaction = (transactionID) => {
    supprimerUnePaiement(transactionID)
      .then((res) => {
        getListeHistoriqueTransaction();
      })
      .catch((error) => {
        console.log("api error", error);
      });
  };

  const getListeHistoriqueTransaction = (reset = null) => {
    setSearchLoading(true);
    getListeStatistiqueTransaction();
    listeDesPaiementPaginee(
      1,
      pageQuery.size,
      reset === null ? param : "",
      reset === null ? modeSelected : "",
      reset === null ? motifSelected : "",
      reset === null ? catechumeneSelected : "",
      reset === null ? dateDebut : "",
      reset === null ? dateFin : "",
      reset === null ? eventSelected : "",
      reset === null ? typeDepense : ""
    )
      .then((res) => {
        setListeTransaction(res.data.results);
        setSearchLoading(false);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        console.log("UNE ERREUR S'EST PRODUITE", error);
        setSearchLoading(false);
      });
  };

  const getListeStatistiqueTransaction = () => {
    recupereRevenuEtDepenseGenere(dateDebut, dateFin)
      .then((res) => {
        setStatistiqueFinancier({
          revenue: res.data.montantRevenu,
          depense: res.data.montantDepense
        });
      })
      .catch((error) => {
        console.log("api error", error);
      });
  };

  const handlePaginationChange = (event, value) => {
    setLoadingData(true);
    setPageQuery((prev) => ({
      ...prev,
      page: value,
      pagePagination: value
    }));
    listeDesPaiementPaginee(
      value,
      pageQuery.size,
      param,
      modeSelected,
      motifSelected,
      catechumeneSelected,
      dateDebut,
      dateFin,
      eventSelected,
      typeDepense
    )
      .then((res) => {
        setListeTransaction(res.data.results);
        setLoadingData(false);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        console.log("UNE ERREUR S'EST PRODUITE", error);
        setLoadingData(false);
      });
  };

  const retournerMontantRestant = (motifId, montant) => {
    let tarif = null;
    let restant = 0;
    listeTarifPaiement.forEach((el) => {
      if (el.motifPaiement && el.motifPaiement._id === motifId) {
        tarif = el.montant;
      }
    });

    restant = tarif && tarif >= 0 ? parseInt(tarif) - parseInt(montant) : 0;

    return tarif && tarif ? (
      <span>
        {restant} <span className="text-[11px]">FCFA</span>
      </span>
    ) : (
      "-"
    );
  };

  // LISTE DES UTILISATEURS ADMINS
  const getListeDesAdministrateurs = () => {
    recupererListeCompleteAdmins()
      .then((res) => {
        setListeAdministrateurs(res.data);
      })
      .catch((error) => {
        console.log("Impossible de récupérer les motifs", error);
      });
  };

  // LISTE MOTIF PAIEMENT
  const getListeDesMotifsDePaiement = () => {
    listeCompleteDesMotifPaiements()
      .then((res) => {
        setListeMotifPaiement(res.data);
      })
      .catch((error) => {
        console.log("Impossible de récupérer les motifs", error);
      });
  };

  // LISTE TARIFS DES MOTIF
  const getListeTarifDesMotifsDePaiement = () => {
    listeCompleteDesTarifications()
      .then((res) => {
        setListeTarifPaiement(res.data);
      })
      .catch((error) => {
        console.log("Impossible de récupérer les tarifs", error);
      });
  };

  // LISTE MODE PAIEMENT
  const getListeDesModesDePaiement = () => {
    listeCompleteDesModePaiements()
      .then((res) => {
        setListeModePaiement(res.data);
      })
      .catch((error) => {
        console.log("Impossible de récupérer les modes", error);
      });
  };

  // LISTE CATECHUMENE
  const getListeCompleteDesCatechumenes = () => {
    listeCompleteDesCatechumenesEnregistre()
      .then((res) => {
        let liste = [];
        if (res.data && res.data.results.length > 0) {
          res.data.results.forEach((element) => {
            const el = {
              label: `${element.catechumene.nom} ${element.catechumene.prenoms}`,
              value: element.catechumene._id
            };
            liste.push(el);
          });
          setListeCatechumene(liste);
        } else {
          setListeCatechumene([]);
        }
      })
      .catch((error) => {
        console.log("Impossible de récupérer les catéchumènes", error);
      });
  };

  // LISTE EVENEMENT
  const getListeCompleteDesEvenements = () => {
    ListeCompleteEvent()
      .then((res) => {
        setListeEvenement(res.data.results);
      })
      .catch((error) => {
        console.log("Impossible de récupérer les events", error);
      });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setTransactionField((prev) => ({
        ...prev,
        photoRecuPaiement: file
      }));
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewRecuPaiement(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const enregistrerUneNouvelleTransaction = () => {
    setAddLoading(true);
  
    // Préparer les données
    const paiementData = new FormData();
    const isDepense = typeTransaction === "DEPENSE";
  
    // Ajouter les champs communs en excluant ceux qui seront ajoutés manuellement
    Object.keys(transactionField).forEach((key) => {
      if (key !== "isDepense") {
        paiementData.append(key, transactionField[key]);
      }
    });
  
    // Ajouter les champs spécifiques
    paiementData.append("isDepense", isDepense);
  
    // Appeler l'API en fonction du type de transaction
    const apiFunction = isDepense
      ? ajouterUneNouvelleDepenseAuPaiement
      : ajouterUnNouveauRevenuPaiement;

    apiFunction(paiementData)
      .then((res) => {
        setAddLoading(false);
        getListeHistoriqueTransaction("reset");
        document.getElementById("enregister-AllTransaction")?.close();
        setTransactionField({
          datePaiement: new Date(),
          photoRecuPaiement: "",
          montant: "",
          commentaire: "",
          isDepense: true,
          personne: "",
          modePaiement: "",
          motifPaiement: "",
          donnateur: "",
          anneeScolaire: currentAnnee ? currentAnnee._id : null,
        });
      })
      .catch((error) => {
        setAddLoading(false);
        console.error("ERROR MESSAGE", error);
      });
  };
  
  // const enregistrerAutreRevenu = () => {
  //   setAddLoading(true);
  //   const paiementData = new FormData();
  //   Object.keys(revenuField).forEach((key) => {
  //     paiementData.append(key, revenuField[key]);
  //   });

  //   ajouterUnNouveauRevenuPaiement(paiementData)
  //     .then((res) => {
  //       setAddLoading(false);
  //       getListeHistoriqueTransaction("reset");
  //       document.getElementById("enregister-AllTransaction").closest();
  //       setPaiementField({
  //         datePaiement: new Date(),
  //         photoRecuPaiement: "",
  //         montant: "",
  //         commentaire: "",
  //         isDepense: true,
  //         personne: "",
  //         donateur: "",
  //         modePaiement: "",
  //         motifPaiement: "",
  //         anneeScolaire: currentAnnee ? currentAnnee._id : null
  //       });
  //     })
  //     .catch((error) => {
  //       setAddLoading(false);
  //       console.log("ERROR MESSAGE", error);
  //     });
  // };

  function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is zero-based, so we add 1
    const year = date.getFullYear();

    const jour = new Date();

    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Pad day and month with leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Construct the formatted date string
    const formattedDate = `${formattedDay}-${formattedMonth}-${year}, ${formattedHours}:${formattedMinutes}`;
    return formattedDate;
  }

  useEffect(() => {
    setLoadingData(true);
    getListeDesMotifsDePaiement();
    getListeTarifDesMotifsDePaiement();
    getListeDesModesDePaiement();
    getListeCompleteDesCatechumenes();
    getListeCompleteDesEvenements();
    getListeDesAdministrateurs();
    recupereRevenuEtDepenseGenere("", "")
      .then((res) => {
        setStatistiqueFinancier({
          revenue: res.data.montantRevenu,
          depense: res.data.montantDepense
        });
      })
      .catch((error) => {
        console.log("api error", error);
      });

    listeDesPaiementPaginee(
      1,
      pageQuery.size,
      param,
      modeSelected,
      motifSelected,
      catechumeneSelected,
      "",
      "",
      eventSelected,
      typeDepense
    )
      .then((res) => {
        setListeTransaction(res.data.results);
        setLoadingData(false);
        setPageQuery((prev) => ({
          ...prev,
          nombrePage: res.data.totalPages,
          totalElements: res.data.totalElements
        }));
      })
      .catch((error) => {
        console.log("UNE ERREUR S'EST PRODUITE", error);
        setLoadingData(false);
      });
  }, []);

  return (
    <BaseLayout>
      <div className="w-full h-full pb-16">
        <div className="flex flex-wrap items-end justify-between gap-4">
          <div className="">
            <h1 className="text-2xl md:text-3xl font-extrabold">
              Historique des transactions
            </h1>
            <p className="mt-1 text-gray-500 font-medium text-sm sm:text-base md:text-lg">
              Détails des transactions effectuées pour cette année.
            </p>
          </div>

          {verificationPermissionsExistantes(listePermissions, "AJOUTER") && (
            <div className="flex flex-row justify-start items-center gap-x-2">
              <button
                className="bg-primary text-sm text-white h-10 w-48 px-4 font-semibold rounded-lg flex items-center justify-center"
                onClick={() => {
                  setTypeTransaction("DEPENSE");
                  document
                    .getElementById("enregister-AllTransaction")
                    .showModal();
                }}
              >
                <p className="">Enregistrer une dépense</p>
              </button>
              <button
                className="bg-black text-sm text-white h-10 w-48 px-4 font-semibold rounded-lg flex items-center justify-center"
                onClick={() => {
                  setTypeTransaction("REVENU");
                  document
                    .getElementById("enregister-AllTransaction")
                    .showModal();
                }}
              >
                <p className="">Enregistrer un révenu</p>
              </button>
            </div>
          )}
        </div>

        {/* STATISTIQUES */}
        <div className="mt-10 flex items-center justify-start gap-x-4">
          <div className="bg-white w-56 h-20 border rounded-xl flex items-center justify-start gap-3 px-3">
            <div className="">
              <img src={IconeBilletArgent} className="h-8 w-8" alt="" />
            </div>
            <div className="">
              <h3 className="text-gray-400 text-sm font-medium">
                Revenu générée
              </h3>
              <span className="font-extrabold text-black text-lg">
                {formatAmountPrice(statistiqueFinancier.revenue)}
              </span>
            </div>
          </div>

          <div className="bg-white w-56 h-20 border rounded-xl flex items-center justify-start gap-3 px-3">
            <div className="">
              <img src={IconeGirlie} className="h-10 w-10" alt="" />
            </div>
            <div className="">
              <h3 className="text-gray-400 text-sm font-medium">
                Sortie de caisse
              </h3>
              <span className="font-extrabold text-black text-lg">
                {formatAmountPrice(statistiqueFinancier.depense)}
              </span>
            </div>
          </div>
        </div>

        {/* FILTRES POSSIBLE */}
        <div className="mt-8 w-full flex items-end gap-x-2 overflow-x-scroll sm:overflow-auto">
          <label className="form-control w-40">
            <div className="label -mb-1">
              <span className="label-text text-xs font-semibold">
                Type transaction
              </span>
            </div>
            <select
              disabled={searchLoading}
              value={typeDepense}
              onChange={(e) => setTypeDepense(e.target.value)}
              className="select select-bordered select-sm custom-select font-medium h-9"
            >
              <option className="font-medium" value="">
                Tout
              </option>
              {[
                {
                  designation: "Dépense",
                  value: "DEPENSE"
                },
                {
                  designation: "Revenu",
                  value: "REVENU"
                }
              ].map((item, index) => (
                <option key={index} value={item.value} className="font-medium">
                  {item.designation}
                </option>
              ))}
            </select>
          </label>

          <label className="form-control w-40">
            <div className="label -mb-1">
              <span className="label-text text-xs font-semibold">
                Motif de paiement
              </span>
            </div>
            <select
              disabled={searchLoading}
              value={motifSelected}
              onChange={(e) => setMotifSelected(e.target.value)}
              className="select select-bordered select-sm custom-select font-medium h-9"
            >
              <option className="font-medium" value="">
                Tout
              </option>
              {listeMotifPaiement.map((item, index) => (
                <option key={index} value={item._id} className="font-medium">
                  {item.designation}
                </option>
              ))}
            </select>
          </label>

          <label className="form-control w-40">
            <div className="label -mb-1">
              <span className="label-text text-xs font-semibold">
                Mode de paiement
              </span>
            </div>
            <select
              disabled={searchLoading}
              value={modeSelected}
              onChange={(e) => setModeSelected(e.target.value)}
              className="select select-bordered select-sm custom-select font-medium h-9"
            >
              <option className="font-medium" value="">
                Tout
              </option>
              {listeModePaiement.map((item, index) => (
                <option key={index} value={item._id} className="font-medium">
                  {item.designation}
                </option>
              ))}
            </select>
          </label>

          <label className="form-control w-52">
            <div className="label -mb-1">
              <span className="label-text text-xs font-semibold">
                Catéchumène
              </span>
            </div>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={listeCatechumene}
              value={catechumeneSelectedEl}
              onChange={(event, newValue) => {
                setCatechumeneSelectedEl(newValue ? newValue : "");
                setCatechumeneSelected(newValue ? newValue.value : "");
              }}
              inputValue={autocompleteInputValue}
              onInputChange={(event, newInputValue) => {
                setAutocompleteInputValue(newInputValue);
              }}
              renderOption={(props, option) => {
                const { key, id, ...optionProps } = props;
                return (
                  <li key={id} {...optionProps}>
                    {option.label}
                  </li>
                );
              }}
              sx={{ width: 200 }}
              renderInput={(params) => <TextField {...params} />}
              disabled={searchLoading}
            />
          </label>

          <label className="form-control w-40">
            <div className="label -mb-1">
              <span className="label-text text-xs font-semibold">
                Evenement
              </span>
            </div>
            <select
              disabled={searchLoading}
              value={eventSelected}
              onChange={(e) => setEventSelected(e.target.value)}
              className="select select-bordered select-sm custom-select font-medium h-9"
            >
              <option className="font-medium" value="">
                Tout
              </option>
              {listeEvenement.map((item, index) => (
                <option key={index} value={item._id} className="font-medium">
                  {item.designation}
                </option>
              ))}
            </select>
          </label>

          <label className="form-control w-fit">
            <div className="label -mb-1">
              <span className="label-text text-xs font-semibold">
                Date début
              </span>
            </div>
            <DatePicker
              locale="fr"
              dateFormat="dd/MM/yyyy"
              disabled={searchLoading}
              selected={dateDebut}
              onChange={(date) => setDateDebut(date)}
              className="font-medium w-32 h-9 text-sm rounded-lg px-3"
            />
          </label>
          <label className="form-control w-fit">
            <div className="label -mb-1">
              <span className="label-text text-xs font-semibold">Date fin</span>
            </div>
            <DatePicker
              locale="fr"
              dateFormat="dd/MM/yyyy"
              disabled={searchLoading}
              selected={dateFin}
              onChange={(date) => setDateFin(date)}
              className="font-medium w-32 h-9 text-sm rounded-lg px-3"
            />
          </label>

          <button
            disabled={searchLoading}
            className="bg-gray-600 text-sm text-white h-10 w-fit px-4 font-semibold rounded-lg flex items-center justify-center"
            onClick={() => getListeHistoriqueTransaction()}
          >
            {!searchLoading ? (
              "Rechercher"
            ) : (
              <ThreeDots
                height="35"
                width="35"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={searchLoading}
              />
            )}
          </button>
        </div>

        {/* LISTE DES PAIEMENTS ENREGISTRÉS */}
        <div className="flex w-full mt-2 h-full">
          <div className="overflow-x-auto bg-white w-full mt-2 ">
            <table className="custom-table table  table-zebra w-full">
              <thead>
                <tr className="bg-black h-12">
                  <th className="text-sm text-white">Date de transaction</th>
                  <th className="text-sm text-white">Evènement</th>
                  <th className="text-sm text-white">Mode paiement</th>
                  <th className="text-sm text-white">Motif paiement</th>
                  <th className="text-sm text-white">Montant payé</th>
                  <th className="text-sm text-white">Effectué par</th>
                  <th className="text-sm text-white">Montant restant</th>
                  <th className="text-sm text-white">Commentaire</th>
                  <th className="text-sm text-white">Actions</th>
                </tr>
              </thead>
              <tbody>
                {!loadingData && listeTransaction.length !== 0 ? (
                  listeTransaction.map((item, index) => (
                    <TableElement key={index} transaction={item} />
                  ))
                ) : !loadingData && listeTransaction.length === 0 ? (
                  <tr>
                    <td colSpan="9">
                      <div className="w-full h-32 bg-white flex gap-x-2 items-center justify-center">
                        <p className="font-semibold text-red-600">
                          Aucun élément trouvé
                        </p>
                      </div>
                    </td>
                  </tr>
                ) : loadingData && listeTransaction.length === 0 ? (
                  <tr>
                    <td colSpan="9">
                      <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 items-center justify-center">
                        <TailSpin
                          height="30"
                          width="30"
                          color="#000"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={loadingData}
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="9">
                      <div className="w-full h-32 bg-white rounded-br-lg rounded-bl-lg flex gap-x-2 items-center justify-center">
                        <TailSpin
                          height="30"
                          width="30"
                          color="#000"
                          ariaLabel="tail-spin-loading"
                          radius="1"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={loadingData}
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <Pagination
            count={pageQuery.nombrePage}
            page={pageQuery.pagePagination}
            variant="outlined"
            shape="rounded"
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {/* MODAL DETAIL D'UN PAIEMENT */}
      <dialog id="detail-paiement" className="modal">
        <div className="modal-box rounded-lg p-4">
          <form method="dialog">
            <button className="w-8 h-8 absolute right-2 top-2">
              <img
                onClick={() => setPaiementSelected(null)}
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-primary">
            Détail de la transaction
          </h3>
          {paiementSelected ? (
            <div className="pt-6 pb-2 space-y-3">
              <div>
                <p className="text-sm text-zinc-500 font-normal">
                  Date de la transaction
                </p>
                <p className="font-medium">
                  {paiementSelected.datePaiement
                    ? formatDate(new Date(paiementSelected.datePaiement))
                    : "-"}
                </p>
              </div>
              <div>
                <p className="text-sm text-zinc-500 font-normal">
                  Effectué par
                </p>
                <p className="font-medium">
                  {paiementSelected.personne
                    ? `${paiementSelected.personne.nom} ${paiementSelected.personne.prenoms}`
                    : "-"}
                </p>
              </div>
              <div className="flex items-start gap-x-6">
                <div>
                  <p className="text-sm text-zinc-500 font-normal">
                    Montant payé
                  </p>
                  <p className="font-medium">
                    {paiementSelected.montant ? paiementSelected.montant : "0"}{" "}
                    <span className="text-[11px]">FCFA</span>
                  </p>
                </div>
                <div>
                  <p className="text-sm text-zinc-500 font-normal">
                    Montant restant
                  </p>
                  <p className="font-medium">
                    {paiementSelected.motifPaiement
                      ? retournerMontantRestant(
                          paiementSelected.motifPaiement._id,
                          paiementSelected.montant
                        )
                      : "Soldé"}
                  </p>
                </div>
              </div>
              <div className="flex items-start gap-x-6">
                <div>
                  <p className="text-sm text-zinc-500 font-normal">
                    Moyen de paiement
                  </p>
                  <p className="font-medium">
                    {paiementSelected.modePaiement
                      ? paiementSelected.modePaiement.designation
                      : "-"}
                  </p>
                </div>
                <div>
                  <p className="text-sm text-zinc-500 font-normal">
                    Motif du paiement
                  </p>
                  <p className="font-medium">
                    {paiementSelected.motifPaiement
                      ? paiementSelected.motifPaiement.designation
                      : "-"}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-sm text-zinc-500 font-normal">Événement</p>
                <p className="font-medium">
                  {paiementSelected.evenement
                    ? paiementSelected.evenement.designation
                    : "-"}
                </p>
              </div>
              <div>
                <p className="text-sm text-zinc-500 font-normal">Commentaire</p>
                <p className="font-medium">{paiementSelected.commentaire}</p>
              </div>
              <div>
                <p className="text-sm text-zinc-500 font-normal">
                  Photo du reçu de paiement
                </p>
                <ImageComponent
                  src={
                    paiementSelected.photoRecuPaiement
                      ? paiementSelected.photoRecuPaiement
                      : ""
                  }
                  alt="photo du reçu"
                  classe="mt-2 w-full h-full rounded-xl object-cover border-2 border-black"
                  height="h-full"
                />
              </div>
            </div>
          ) : null}
          <div className="modal-action">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button
                className="w-28 h-9 rounded bg-zinc-300 text-zinc-600 text-sm font-semibold"
                onClick={() => setPaiementSelected(null)}
              >
                Fermer
              </button>
            </form>
          </div>
        </div>
      </dialog>

      {/* MODAL POUR ENREGISTRER UNE DEPENSE */}
      <dialog id="enregister-AllTransaction" className="modal">
        <div className="modal-box rounded-lg p-4">
          <form method="dialog">
            <button className="w-8 h-8 absolute right-2 top-2">
              <img
                // onClick={() => setPaiementSelected(null)}
                src={iconeCloseWindow}
                className="cursor-pointer w-full"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-extrabold text-xl text-black">
            Enregistrer{" "}
            {typeTransaction === "DEPENSE" ? "une dépense" : "un revenu"}
          </h3>
          <div className="pt-8 pb-4">
              <div>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Date du paiement
                    </span>
                  </div>
                  <DatePicker
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                    selected={transactionField.datePaiement}
                    onChange={(date) =>
                      setTransactionField((prev) => ({
                        ...prev,
                        datePaiement: date
                      }))
                    }
                    className="font-medium w-full h-9 text-sm rounded-lg px-3"
                  />
                </label>
              </div>
              <div>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Montant payé (FCFA)
                    </span>
                  </div>
                  <input
                    type="number"
                    value={transactionField.montant}
                    onChange={(e) =>
                      setTransactionField((prev) => ({
                        ...prev,
                        montant: e.target.value
                      }))
                    }
                    disabled={addLoading}
                    placeholder="Entrer un montant"
                    className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                  />
                </label>
              </div>
              <div className="flex flex-col sm:flex-row items-end gap-x-3">
                <div className="w-1/2">
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-bold text-gray-500 text-sm">
                        Moyen de paiement
                      </span>
                    </div>
                    <select
                      value={transactionField.modePaiement}
                      onChange={(e) => {
                        setTransactionField((prev) => ({
                          ...prev,
                          modePaiement: e.target.value
                        }));
                      }}
                      className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                    >
                      <option disabled selected value="">
                        Choisir un élément
                      </option>
                      {listeModePaiement.map((item, index) => (
                        <option
                          key={index}
                          value={item._id}
                          className="font-medium"
                        >
                          {item.designation}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className="w-1/2">
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-bold text-gray-500 text-sm">
                        Motif de paiement
                      </span>
                    </div>
                    <select
                      value={transactionField.motifPaiement}
                      onChange={(e) => {
                        setTransactionField((prev) => ({
                          ...prev,
                          motifPaiement: e.target.value
                        }));
                      }}
                      className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                    >
                      <option disabled selected value="">
                        Choisir un élément
                      </option>
                      {listeMotifPaiement.map((item, index) => (
                        <option
                          key={index}
                          value={item._id}
                          className="font-medium"
                        >
                          {item.designation}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>
              <div>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Effectué par
                    </span>
                  </div>
                  <select
                    value={transactionField.personne}
                    onChange={(e) => {
                      setTransactionField((prev) => ({
                        ...prev,
                        personne: e.target.value
                      }));
                    }}
                    className="select select-bordered custom-select h-10 font-medium bg-[#F9F9F9]"
                  >
                    <option disabled selected value="">
                      Choisir un élément
                    </option>
                    <option value="">
                      Aucun
                    </option>
                    {listeAdministrateurs.map((item, index) => (
                      <option
                        key={index}
                        value={item._id}
                        className="font-medium"
                      >
                        {item.nom} {item.prenoms}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              {typeTransaction !== "DEPENSE" ? (
                <div>
                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-bold text-gray-500 text-sm">
                        Donateur
                      </span>
                    </div>
                    <input
                      type="text"
                      value={transactionField.donnateur}
                      onChange={(e) =>
                        setTransactionField((prev) => ({
                          ...prev,
                          donnateur: e.target.value
                        }))
                      }
                      disabled={addLoading}
                      placeholder="Entrer nom du donateur"
                      className="input input-bordered w-full h-10 font-medium bg-[#F9F9F9]"
                    />
                  </label>
                </div>
              ) : null}

              <div>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Commentaire
                    </span>
                  </div>
                  <textarea
                    type="number"
                    value={transactionField.commentaire}
                    onChange={(e) =>
                      setTransactionField((prev) => ({
                        ...prev,
                        commentaire: e.target.value
                      }))
                    }
                    disabled={addLoading}
                    placeholder="Entrer un commentaire"
                    className="input input-bordered w-full h-28 pt-2 text-base font-medium bg-[#F9F9F9]"
                  />
                </label>
              </div>
              <div>
                <label className="form-control w-full">
                  <div className="label">
                    <span className="label-text font-bold text-gray-500 text-sm">
                      Reçu de paiement
                    </span>
                  </div>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="file-input file-input-bordered file-input-success w-full font-medium"
                  />
                </label>
              </div>
            </div>

          <div className="modal-action">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className="w-28 h-10 rounded-lg bg-zinc-300 text-zinc-600 text-sm font-semibold">
                Annuler
              </button>
            </form>
            <button
              onClick={
                enregistrerUneNouvelleTransaction
              }
              disabled={addLoading}
              className="bg-primary flex items-center justify-center w-32 h-10 font-bold text-white rounded-lg hover:drop-shadow-md"
            >
              {!addLoading ? (
                "Enregistrer"
              ) : (
                <ThreeDots
                  height="40"
                  width="40"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={addLoading}
                />
              )}
            </button>
          </div>
        </div>
      </dialog>
    </BaseLayout>
  );
};

export default Finances;
