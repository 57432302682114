import React, { useEffect, useRef, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import PRINT_FILE from "./../../../assets/IconeSvg/print-file.svg";
import LOGO from "./../../../assets/images/pssm_logo.jpg";
import SEIGNEUR from "./../../../assets/images/seigneur.jpg";
import { listeCompleteDesCatechumenesParClasse } from "../../../services/historique-catechumene/HistoriqueCatechumeneRequest";
import html2canvas from "html2canvas-pro";
import { Grid, Oval } from "react-loader-spinner";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import { listeCompleteDesAnimateurs } from "../../../services/animateur/AnimateurRequest";

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

const PrintBadgeAnimateur = () => {
  const { classeId } = useParams();
  const [loadingData, setLoadingData] = useState(true);
  const [listeAnimateurs, setListeAnimateurs] = useState([]);

  const handleOnClick = () => {
    window.print();
  };

  // === CARTE À IMPRIMER ===
  const PrintedCard = ({ content }) => {
    return (
      <div className="relative card-box w-full h-full border bg-white p-1">
        <div className="backgroung-cover absolute inset-0 w-full h-full"></div>
        <div className="z-50">
          {/* INFORMATION DE LA PAROISSE */}
          <div className="flex items-center justify-between gap-x-2">
            <div className="w-12 h-12 print:w-9 print:h-9 rounded-full bg-gray-100 border-2 border-black">
              <img
                src={LOGO}
                className="w-full h-full object-cover rounded-full"
              />
            </div>
            <div>
              <p className="text-center text-xs print:text-[10px] text-black uppercase font-bold">
                Diocèse de Yopougon
              </p>
              <p className="text-center text-xs print:text-[8px]">
                Paroisse Saint Sauveur Miséricordieux
              </p>
              <p className="text-center text-xs print:text-[10px] text-black uppercase font-bold mt-px">
                Commission catéchèse
              </p>
            </div>
            <div className="w-12 h-16 print:w-9 print:h-12 rounded-full bg-gray-100">
              <img src={SEIGNEUR} className="w-full h-full object-cover" />
            </div>
          </div>
          <div className="-mt-1">
            <p className="text-center text-[10px] print:text-[8px] text-black">
              « Servire dans l'humilité »
            </p>
            <p className="text-center text-[10px] print:text-[8px]">
              23 BP 3330 Abidjan 23 - Tél: 07 07 66 23 05
            </p>
            <p className="text-center text-[10px] print:text-[8px] text-black mt-px">
              Email: catepssm@gmail.com - Tél: 0748070023/ 0505830856
            </p>
          </div>
          {/* INFORMATION DU CATÉCHUMENE */}
          <div className="mt-3 print:mt-1 divider divider-error">
            <p className="text-primary text-lg print:text-xs font-extrabold uppercase text-center">
              ANIMATEUR
            </p>
          </div>
          <p className="-mt-2 print:-mt-4 text-black text-sm print:text-[9px] font-extrabold text-center">
            Année paroissiale: {currentAnnee ? `${currentAnnee.anneeDebut}-${currentAnnee.anneeFin}` : null}
          </p>
          <div className="mt-3 print:mt-1 pb-3 print:pb-3 relative px-4 print:px-1 print:space-y-2 flex items-start gap-4">
            <div className="w-32 mx-auto h-28 print:w-20 print:h-20">
              <img
                src={content.photo}
                className="w-full h-full object-contain"
              />
            </div>
            <div className="w-full">
              <div className="flex items-start gap-x-1">
                <p className="text-xs font-medium underline">
                  Niveau catéchétique:
                </p>
                <p className="text-sm print:text-xs text-black font-bold">
                  {content.classe.niveau.designation}
                </p>
              </div>
              <div className="flex items-start gap-x-1 mt-1">
                <p className="text-xs font-medium underline">Sous Commission:</p>
                <p className="text-sm print:text-xs text-black font-bold">
                  {content.classe.commission.designation}
                </p>
              </div>
              <div className="flex items-start gap-x-1 mt-1">
                <p className="text-xs font-medium underline">Nom:</p>
                <p className="text-sm print:text-xs text-black font-bold uppercase">
                  {content.nom}
                </p>
              </div>
              <div className="flex items-start gap-x-1 mt-1">
                <p className="text-xs font-medium underline">Prénoms:</p>
                <p className="text-sm print:text-xs text-black font-bold uppercase truncate">
                  {content.prenoms}
                </p>
              </div>
              <div className="flex items-start gap-x-1 mt-1">
                <p className="text-xs font-medium underline">Contacts:</p>
                <p className="text-sm print:text-xs text-black font-bold">
                  {content.contact ? content.contact : "-"} /{" "}
                  {content.secondContact ? content.secondContact : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    listeCompleteDesAnimateurs()
      .then((res) => {
        setLoadingData(false);
        setListeAnimateurs(res.data);
      })
      .catch((error) => {
        setLoadingData(false);
        console.log("error", error);
      });
  }, []);

  return (
    <div className="w-full min-h-screen bg-zinc-100">
      {/* HEADER */}
      <div className="sticky top-0 print:hidden w-full px-4 h-16 bg-black drop-shadow-lg z-50">
        <div className="w-full h-full flex items-center justify-between">
          <NavLink end to={`/animateurs`}>
            <button className="w-fit px-4 h-fit py-1 bg-[#343434] rounded-md flex items-center justify-center text-white text-sm font-semibold">
              Retour
            </button>
          </NavLink>
          <button
            className="w-fit px-6 h-9 bg-sky-600 rounded-full flex items-center justify-center gap-x-1 text-white text-xs font-semibold"
            onClick={() => handleOnClick()}
          >
            <img src={PRINT_FILE} className="w-5" />
            Imprimer les badges
          </button>
        </div>
      </div>
      {/* LISTE BADGE */}
      {!loadingData && listeAnimateurs.length !== 0 ? (
        <div className="card-container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 print:grid-cols-3 print:gap-3 print:w-[297mm] print:mx-auto [@media_print]:{break-inside-avoid}">
          {listeAnimateurs.map((item, index) => (
            <div
              key={index}
              className="print:break-inside-avoid print:w-[99mm] print:h-[66mm] print:transform print:origin-top-left"
            >
              <PrintedCard content={item} />
            </div>
          ))}
        </div>
      ) : null}
      {loadingData && listeAnimateurs.length === 0 ? (
        <div className="w-full pt-16">
          <div className="w-fit mx-auto h-40 flex flex-col items-center justify-center">
            <Oval
              visible={true}
              height="30"
              width="30"
              strokeWidth="4"
              color="#000"
              secondaryColor="#9ca3af"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
            <p className="mt-3 text-sm text-black font-medium">
              Chargement des badges...
            </p>
          </div>
        </div>
      ) : null}
      {!loadingData && listeAnimateurs.length === 0 ? (
        <div className="pt-16">
          <p className="text-lg text-red-600 text-center font-semibold">
            Aucun catéchumène trouvé pour cette classe.
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default PrintBadgeAnimateur;
